import {ScoreSheet, StoreTypes} from "../types";

class PersistentStore {
    static SCORE_VAR: string = 'KP_CURRENT_SCORE';
    static SCORE_SHEET_VAR: string = 'KP_SCORE_SHEET';
    static INTEGRITY_HASH: string = 'KP_INTEGRITY_HASH';
    static JWT: string = 'kit.access_token';

    static save = (type: StoreTypes, payload: any): void => {
        if (!(typeof payload === 'object' || typeof payload === 'string')) {
            console.warn("Input type to store is invalid type: ", typeof payload);
            return;
        }
        localStorage.setItem(PersistentStore._getVarKey(type), JSON.stringify(payload));
    }
    static get = (type: StoreTypes): any => {
        let payload = localStorage.getItem(PersistentStore._getVarKey(type))
        if (!payload) return null;
        try {
            payload = JSON.parse(payload);
        } catch (err) {
        }
        return payload;
    }

    static delete = (type: StoreTypes): void => {
        localStorage.removeItem(PersistentStore._getVarKey(type))
    }

    static addNewScoreSheetEntry = (puzzleId: number, name: string, score: number) => {
        let sheet: ScoreSheet = PersistentStore.get('sheet');
        if (!sheet || typeof sheet !== 'object' || Array.isArray(sheet)) sheet = {};
        const pId = puzzleId.toString();
        const scores = pId in sheet ? sheet[pId] : [];
        scores.push({name, score})
        scores.sort((a: any, b: any) => (a.score > b.score) ? 1 : -1);
        scores.splice(10);
        sheet = {...sheet, [pId]: scores};
        PersistentStore.save('sheet', sheet);
    }

    static _getVarKey = (type: StoreTypes) => {
        if (type === 'sheet') return PersistentStore.SCORE_SHEET_VAR;
        if (type === 'jwt') return PersistentStore.JWT;
        if (type === 'hash') return PersistentStore.INTEGRITY_HASH;
        return PersistentStore.SCORE_VAR;
    }

}

export default PersistentStore;