import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import StoreProvider from "./Store";
import {ErrorBoundary} from "react-error-boundary";
import Error from "./components/views/Error/error";

ReactDOM.render(
  <React.StrictMode>
      <StoreProvider>
          <ErrorBoundary FallbackComponent={Error} onError={() => {console.warn("BoundaryError: REMEMBER TO LOG ME!!")}}>
              <App />
          </ErrorBoundary>
      </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
