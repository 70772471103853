class Color {
    static YELLOW: string = "#FFD959";
    static YELLOW_LIGHT: string = "#ffe89b";
    static PURPLE: string = "#8A4AE2";
    static RED: string = "#FF80AA";
    static BLUE: string = "#65D8FF";
    static WHITE: string = "#FFFFFF";
    static GREEN: string = "#9FE64A";
}

export default Color;