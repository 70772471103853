import React from 'react';
import {Route, Switch, useLocation} from "react-router-dom";
import Main from "./components/views/Main/main";
import Color from "./common/classes/Color";
import Skattejakt from "./components/views/Skattejakt/skattejakt";
import Highscore from "./components/views/Skattejakt/Highscore/highscore";
import Result from "./components/views/Skattejakt/Result/result";
import Overview from "./components/views/Skattejakt/Overview/overview";
import Post from "./components/views/Skattejakt/Post/post";
import Task from "./components/views/Skattejakt/Post/Task/task";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {BasePageProps} from "./common/types";
import styled from "styled-components";
import PageNotFound from "./components/views/PageNotFound/pagenotfound";
import ErrorInvoker from "./components/modules/ErrorInvoker/errorinvoker";
import LoginCallback from "./components/views/LoginCallback/logincallback";
import Logout from "./components/views/Logout/logout";


export interface RoutesProps extends BasePageProps {
}

const Container = styled.div`
  display: flex;

  main {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .slide-enter {
    transform: translateX(100%);
  }

  .slide-enter-active {
    transform: translateX(0%);
    transition: transform 300ms ease-in-out;
  }

  .slide-exit {
    transform: translateX(0%);
    filter: brightness(1);
  }

  .slide-exit-active {
    transform: translateX(-100%);
    filter: brightness(0.5);
    transition: transform 300ms ease-in-out, filter 300ms;
  }
`;

const MainRoutes = [
    {
        path: "/",
        exact: true,
        Component: Main
    },
    {
        path: "/oauth2/callback",
        exact: true,
        Component: LoginCallback
    },
    {
        path: "/logout",
        exact: true,
        Component: Logout
    },
    {
        path: "/skattejakt/:puzzleId",
        exact: true,
        Component: Skattejakt
    },
    {
        path: "/skattejakt/:puzzleId/highscore",
        exact: true,
        Component: Highscore
    },
    {
        path: "/skattejakt/:puzzleId/results",
        exact: true,
        Component: Result
    },
    {
        path: "/skattejakt/:puzzleId/overview",
        exact: false,
        Component: Overview
    },
    {
        path: "/skattejakt/:puzzleId/post/:postId",
        exact: true,
        Component: Post
    },
    {
        path: "/skattejakt/:puzzleId/post/:postId/task/:taskId",
        exact: true,
        Component: Task
    },
    {
        path: "/testerror",
        exact: true,
        Component: ErrorInvoker
    },
    {
        path: "*",
        exact: false,
        Component: PageNotFound
    }
]

const Routes: React.FC<RoutesProps> = ({...pageProps}) => {
    const location = useLocation();
    return (
        <Container>
            <TransitionGroup>
                <CSSTransition timeout={300} classNames="slide"
                               key={!location?.pathname?.includes('/overview') ? location.key : null}>
                    <Switch location={location}>
                        {MainRoutes.map(({path, exact, Component}) => (
                            <Route path={path} key={path} exact={exact}>
                                <Component {...pageProps} bgColor={path === '/' ? Color.PURPLE : Color.YELLOW}/>
                            </Route>
                        ))}
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        </Container>
    );
};

export default Routes;