import React, {FC} from 'react';
import styled from "styled-components";


export interface ProgressBarProps {
    value?: number
}

const Container = styled.div`
  width: 256px;
  height: 15px;
  border: 2px solid #000;
  border-radius: 10px;
  margin: 0 auto 20px;
  overflow: hidden;
  position: relative;
`;

const Progress = styled.progress`
  width: 100%;
  position: absolute;
  height: 100%;
  border-radius: 10px;
  color: #FFD959;
  background-color: transparent;
  ::-webkit-progress-bar{
    background: transparent;
  }
  ::-webkit-progress-value{
    background: #FFD959;
  }
  ::-moz-progress-bar {
    background: #FFD959;
  }
`;

const ProgressBar: FC<ProgressBarProps> = ({value}) => {
    return(
        <Container>
            <Progress color='red' value={value ?? 0} max={100}>

            </Progress>
        </Container>
    )
}

export default ProgressBar;