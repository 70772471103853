import {useEffect, useState} from "react";
import {Position} from "../../common/types";


const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
}

const error = (data?: any): void => {
    console.warn("Geolocation error. Err: ", data?.message)
}


const usePosition = () => {
    const [position, setPosition] = useState<Position>();

    const success = (data: any): void => {
        const { latitude, longitude } = data?.coords;
        setPosition({
            latitude,
            longitude
        });
    }

    useEffect(() => {
        if (!navigator?.geolocation) return;
        let id = navigator.geolocation.watchPosition(success, error, options);
        return () => {
            navigator.geolocation.clearWatch(id);
        }
    }, [])

    return position;
};

export default usePosition;