import React from 'react';
import styled from "styled-components";
import {FallbackProps} from "react-error-boundary";

const Container = styled.div`
  background: #a2a2a2;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Box = styled.div`
  margin: 50px 0;
  width: 315px;
  background: rgba(255,255,255,0.85);
  padding: 20px;
  border-radius: 12px;
  border: 3px solid #ffffff;
  box-shadow: 5px 5px 25px rgb(0 0 0 / 40%);
  > * {
    margin: 10px 0;
  }
  > button {
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    background: rgba(0,0,0,0.2);
  }

`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const Error: React.FC<FallbackProps> = ({error, resetErrorBoundary}) => {
    return (
        <Container>
            <Box>
                <Title>Noe gikk galt!</Title>
                <div>
                    {error?.message ? ("Error: " + error.message) : 'Error!'}
                </div>
                <div>
                    <button onClick={resetErrorBoundary}>Prøv igjen</button>
                    <button onClick={() => {window.location.href = '/'}}>Start på nytt</button>
                </div>
            </Box>
        </Container>
    )
};

export default Error;