import {useContext} from 'react';
import {Store} from "../../Store";
import {User} from "../../common/types";


const useStore = () => {
    const { state, dispatch } = useContext(Store);

    const setUser = (user: User | null) => {
        dispatch({type: 'SET_USER', payload: user})
    }

    return { state, dispatch, setUser };
}

export default useStore;