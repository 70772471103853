import React from 'react';
import styled from "styled-components";
import Icon, {IconType} from "../Icon/icon";

export interface MetaProps {
    icon?: IconType;
    label?: string;
}

const Container = styled.div`
   display: flex;
  align-items: center;
`;

const Label = styled.span`
  font-weight: 900;
  margin-left: 10px;
`;

const Meta: React.FC<MetaProps> = ({label, icon}) => {
    return (
        <Container>
            { icon ? <Icon icon={icon}/> : null }
            <Label>
                { label }
            </Label>
        </Container>
    );
};

export default Meta;