import {useContext, useEffect, useState} from "react";
import {Store} from "../../Store";
import {useParams} from "react-router-dom";
import {Puzzle} from "../../common/types";
import Score from "../../common/classes/Score";

const usePuzzleCompleted = () => {
    const { state } = useContext(Store);
    const { scoreCard } = state;
    const [completed, setCompleted] = useState(false);
    const { puzzleId } = useParams<any>();

    useEffect( () => {
       if (!(scoreCard?.puzzles && puzzleId)) return;
       const puzzleScore = scoreCard.puzzles.find( (_puzzle: Puzzle) => _puzzle.id === parseInt(puzzleId));
       if (Score.isAllPostsComplete(puzzleScore)){
           setCompleted(true);
           return;
       }

       setCompleted(false);
    }, [scoreCard, puzzleId]);
    return completed;
};

export default usePuzzleCompleted;