import React, {useState} from 'react';
import ShadowContainer from "../../framework/ShadowContainer/shadowcontainer";
import Button from "../Button/button";
import styled from "styled-components";
import {ResultFormData} from "../../../common/types";

export interface ResultFormProps {
    title?: string;
    body?: string;
}

const Container = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  .button-container {
    margin-top: 5px;
  }
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-weight: 900; 
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContent = styled.div`
    padding: 10px 0;
  text-align: center;
    :not(:first-of-type){
      border-top: 1px dotted #DDD;
    }
`;

const InputContainer = styled.div`
  margin: 5px 0;
    display: flex;
    flex-direction: column;
  label {
    margin: 5px 0;
  }
    input {
      width: 100%;
      border-radius: 5px;
      background-color: #fff;
      padding: 10px 15px;
      border: 1px solid #aaa;
    }
`;


const ResultForm: React.FC<ResultFormProps> = ({title, body}) => {
    const [ form, setForm ] = useState<ResultFormData>({
        name: "",
        email: "",
        number: ""
    });

    const inputHandler = (e?: React.ChangeEvent<HTMLInputElement> | undefined ) => {
        if (!e) return;
        setForm( (prev: ResultFormData) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    return (
        <ShadowContainer>
            <Container>
                <Title>
                    { title ?? 'BRA JOBBA!'}
                </Title>
                <FormContent>
                    { body ?? 'Vær med i trekningen om en flott premie.'}
                </FormContent>
                <InputContainer>
                    <label>Ditt navn</label>
                    <input name="name" type="text" value={form.name} onChange={inputHandler}/>
                </InputContainer>
                <InputContainer>
                    <label>Din epostadresse</label>
                    <input name="email" type="email" value={form.email} onChange={inputHandler}/>
                </InputContainer>
                <InputContainer>
                    <label>Ditt telefonnummer</label>
                    <input name="number" type="number" value={form.number} onChange={inputHandler}/>
                </InputContainer>
                <div className={'button-container disabled'}>
                    <Button disabled label="Send" onClick={() => {}}/>
                </div>
            </Container>
        </ShadowContainer>

    );
};

export default ResultForm;