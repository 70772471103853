import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import Header from "../../../framework/Header/header";
import ScoreList from "../../../modules/ScoreList/scorelist";
import {ScoreUnitProps} from "../../../modules/ScoreUnit/scoreunit";
import Share from '../../../modules/Share/share';
import {BasePageProps, ScoreEntry, ScoreSheet} from "../../../../common/types";
import PersistentStore from "../../../../common/classes/PersistentStore";
import {useParams, useRouteMatch} from "react-router-dom";
import Score from "../../../../common/classes/Score";
import Footer from "../../../framework/Footer/footer";
import SkattejaktService from "../../../../common/core/SkattejaktService";
import useStore from "../../../hooks/useStore";
import Button from "../../../modules/Button/button";
import Color from "../../../../common/classes/Color";

export interface HighscoreProps extends BasePageProps {
}

const Container = styled.main<HighscoreProps>`
  height: 100%;
  background: ${({bgColor}) => bgColor};
  display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  &.social {
    margin-bottom: 3em;
  }
`;

const Title = styled.h1`
  margin: 40px 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  font-weight: 900;
`;

const NameChangeContainer = styled.div`
  margin: 0 0 2em 0;
  align-self: center;
  width: 315px;
  display: grid;
  grid-template-columns: 70% auto;
  justify-content: space-between;

  h3 {
    grid-column: 1 / span 2;
    margin-bottom: 0.25em;
    margin-left: 0.25em;
  }
`;

const DisplayNameInput = styled.input`
  align-self: center;
  font-size: 1.5rem;
  border-radius: 10px;
  height: 2em;
  padding: 0.5em;
  border: 3px solid black;
`;

const Highscore: React.FC<HighscoreProps> = (props) => {
    const {state} = useStore();
    const {url} = useRouteMatch();
    const [displayName, setDisplayName] = useState<string | undefined>(state?.user?.displayName)
    const {puzzleId} = useParams<any>();
    const localScores: ScoreEntry[] = useMemo(() => {
        let _sheet: ScoreSheet = PersistentStore.get('sheet');
        if (!(puzzleId && _sheet && puzzleId in _sheet)) return [];
        let {[puzzleId]: _scores} = _sheet;
        if (!_scores) return [];
        _scores.sort((a: any, b: any) => a?.score > b?.score ? -1 : 1);
        return _scores;
    }, [puzzleId])
    const [remoteScores, setRemoteScores] = useState<ScoreUnitProps[]>([]);


    const score = useMemo(() => {
        if (!(props?.scoreCard && url)) return 0;
        return Score.parseScoreByUrl(props.scoreCard, url);
    }, [url, props]);

    const updateDisplayName = (e: any) => {
        if (typeof e.target.value !== 'string') return;
        setDisplayName(e.target.value);
    }

    const updateScores = useCallback(() => {
        new SkattejaktService().getHighScore(parseInt(puzzleId), 'lastYear')
            .then((data: any) => {
                if (data?.results) {
                    let scores = data.results.map((x: any) => ({
                        name: x.displayName,
                        score: x.points
                    }))
                    setRemoteScores(scores);
                }
            })
    }, [puzzleId])

    const changeDisplayName = () => {
        try {
            if (!(state?.user?.id && displayName)) throw new Error("User dont exist or empty input");
            new SkattejaktService()
                .updateUser({id: state?.user?.id, displayName: displayName})
                .then((data: any) => {
                    if (!data?.id) throw new Error("Api could not update name");
                    console.log("ok");
                    updateScores();
                })
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        if (!puzzleId && updateScores) return;
        updateScores();
    }, [puzzleId, updateScores])

    return (
        <Container {...props}>
            <Header type="score" score={score} onCloseClick={props.toggleCloseMenu}/>
            <Title>Toppliste</Title>
            {state?.user ?
                <NameChangeContainer>
                    <h3>Ditt visningnavn</h3>
                    <DisplayNameInput id='displayName' onChange={updateDisplayName} value={displayName}/>
                    <Button onClick={changeDisplayName} label="Endre" bgColor={Color.WHITE}/>
                </NameChangeContainer>
                : null}
            <Section>
                <ScoreList maxCount={10} results={remoteScores}/>
            </Section>
            <Title>Din toppliste</Title>
            <Section>
                <ScoreList maxCount={10} results={localScores}/>
            </Section>
            <Section className="social">
                <Share/>
            </Section>
            <Footer fill/>
        </Container>
    );
};

export default Highscore;