import React from 'react';
import styled from "styled-components";
import Button from "../../modules/Button/button";
import Color from "../../../common/classes/Color";

export interface CloseOverlayProps {
   onStay?: () => void;
   onClose?: () => void;
}

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  background: rgba(255,255,255,0.7);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  > div {
    margin: 25px;
  }
  .text {
    text-align: center;
    font-size: 2em;
    font-weight: 900;
    max-width: 317px;
    padding: 24px;
    border: 2px solid #000;
    border-radius: 20px;
    background: #FFFFFF;
    margin-bottom: 30px;
    transform: rotate(
            -1deg
    );
  }
`;

const CloseOverlay: React.FC<CloseOverlayProps> = ({onClose, onStay}) => {
    return (
        <Container onClick={onStay}>
            <Content>
                    <div className={'text'}>Avslutte og gå tilbake til KulturPunkt?</div>
                <div>
                    <Button onClick={onClose} size="large" bgColor={Color.RED} label="Avslutt"/>
                </div>
                <div>
                    <Button onClick={onStay} size="large" bgColor={Color.GREEN} label="Bli værende"/>
                </div>
            </Content>
        </Container>
    );
};

export default CloseOverlay;