import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import Header from "../../../framework/Header/header";
import {BasePageProps} from "../../../../common/types";
import Button from "../../../modules/Button/button";
import Color from "../../../../common/classes/Color";
import ShadowContainer from "../../../framework/ShadowContainer/shadowcontainer";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import Score from "../../../../common/classes/Score";

export interface PostProps extends BasePageProps {
}

const Container = styled.main<{ bgColor?: string }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({bgColor}) => bgColor};
`;

const Content = styled.section`
  margin: 25px 0;
  align-self: center;
  display: flex;
  justify-content: space-between;
`;

const PageHeader = styled.div`
  width: 331px;
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #FFFFFF;

  > h1 {
    padding: 10px 15px;
  }

  > p {
    padding: 10px 15px;
  }
`;

const Post: React.FC<PostProps> = ({
                                       bgColor,
                                       puzzles,
                                       toggleCloseMenu,
                                       scoreCard
                                   }) => {
    const {url} = useRouteMatch();
    const {puzzleId, postId} = useParams<any>();
    const history = useHistory();
    const post = useMemo(() => {
        if (!(puzzles && postId && puzzleId)) return undefined;
        return puzzles?.[puzzleId - 1]?.posts?.[postId - 1] ?? undefined;
    }, [puzzleId, postId, puzzles])

    const score = useMemo( () => {
        if (!(url && scoreCard)) return 0;
        return Score.parseScoreByUrl(scoreCard, url);
    }, [url, scoreCard])

    const startClick = useCallback(() => {
        if (!(history && url)) return;
        history.push(`${url}/task/1`);
    }, [url, history]);

    return (
        <Container bgColor={bgColor}>
            <Header type="score" score={score} onCloseClick={toggleCloseMenu}/>
            <Content>
                <ShadowContainer>
                    <PageHeader>
                        <h1>
                            {(post?.title ?? 'Her er det oppgaver') + ' (4)'}
                        </h1>
                        <p>
                            {'Her er det beskrivelse'}
                        </p>
                    </PageHeader>
                </ShadowContainer>
            </Content>
            <Content>
                <Button onClick={startClick} bgColor={Color.GREEN} size="large" label="Start"/>
            </Content>
        </Container>
    );
};

export default Post;