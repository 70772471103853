import Api from "./Api";
import {ScorePayload, TimeSpan, User} from "../types";

class SkattejaktService extends Api {
    constructor() {
        super("self");
    }
    getHighScore = async (puzzleId: number, from?: TimeSpan) => {
        return  []
        // return this.get(`/results?puzzleId=${puzzleId}${from ? '&from=' + from : null}`);
    }
    postScore = async (payload: ScorePayload) => {
        return;
        //return this.post(`/results`, payload);
    }
    updateUser = async (payload: User) => {
        return;
        //return this.put(`/users/${payload.id}`, payload);
    }
    getUserInfo = async () => {
        return {};
        //return this.get(`/users/userInfo`);
    }
}

export default SkattejaktService;