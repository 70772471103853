import React from 'react';
import ScoreUnit, {ScoreUnitProps} from "../ScoreUnit/scoreunit";
import styled from "styled-components";
import Color from "../../../common/classes/Color";

export interface ScoreListProps {
    results: ScoreUnitProps[];
    maxCount?: number;
}


const Container = styled.ul`
    display: flex;
    flex-direction: column;
    width: 315px;
    > li:nth-child(-n+3) {
      margin-bottom: 16px;
    }
    > li:nth-child(4) {
      margin-bottom: 32px;
    }
`;

const getColor = (pos?: number): string => {
    if (typeof pos !== 'number') return Color.WHITE;
    if (pos < 2) return Color.YELLOW;
    if (pos < 5) return Color.BLUE;
    return Color.WHITE;
}

const ScoreList: React.FC<ScoreListProps> = ({results, maxCount}) => {

    return (
        <Container>
            { results.slice(0,maxCount ?? 10).map( (result, i) => {
                return(<ScoreUnit
                    key={i + '-' + result.name + '-' + result.score}
                    position={result.position ?? (i+1)}
                    name={result.name}
                    score={result.score}
                    bgColor={getColor(result.position ?? i+1)}
                />)
            })}
        </Container>
    );
};

export default ScoreList;