import React, {FC} from 'react';
import styled from "styled-components";

import { ReactComponent as TreasureIcon } from "../../../assets/img/fig_treasure.svg";
import ProgressBar from "../../modules/ProgressBar/progressbar";
import Icon from "../../modules/Icon/icon";


export interface LoadingProps {
    bgColor?: string;
    value?: number;
    progressValue?: number;
}

const Container = styled.main<LoadingProps>`
  height: 100%;
  background: ${ (props) => props?.bgColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  margin: 10vh 0;
`;

const Content = styled.section`
   display: flex;
  flex-direction: column;
  align-items: center;
`;


const ProgressContainer = styled.div`
  margin: 5vh 0;
  > p {
    font-weight: 900;
    letter-spacing: 0.15em;
    color: #fff;
    text-transform: uppercase;
    text-align: center
  }
`;

const Title = styled.h1`
  color: #FFD959;
  font-size: 4rem;
  font-weight: 900;
  letter-spacing: -0.02em;
  margin: 20px 0 0;
`;

const Loading: FC<LoadingProps> = ({progressValue, bgColor}) => {

    return (
        <Container bgColor={bgColor}>
            <Header>
                <Icon icon="logoWhite"/>
            </Header>
            <Content>
                <TreasureIcon/>
                <Title>Skattejakt</Title>
            </Content>
            <ProgressContainer>
                <ProgressBar value={progressValue}/>
                <p>Laster inn</p>
            </ProgressContainer>
        </Container>
    );
};

export default Loading;