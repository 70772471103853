import React, {useEffect} from 'react';
import {useErrorHandler} from "react-error-boundary";

const ErrorInvoker: React.FC = () => {
    const error = useErrorHandler();

    useEffect( () => {
        error?.("error test");
    }, [error])

    return(
        <div>
            I will crash
        </div>
    )
};

export default ErrorInvoker;