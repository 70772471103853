import {FC} from "react";
import {ReactComponent as GpsIcon} from "../../../assets/img/icon_marker.svg";
import {ReactComponent as UserIcon} from "../../../assets/img/icon_user.svg";
import {ReactComponent as CloseIcon} from "../../../assets/img/icon_close.svg";
import {ReactComponent as BackIcon} from "../../../assets/img/icon_back.svg";
import {ReactComponent as StarIcon} from "../../../assets/img/icon_star.svg";
import {ReactComponent as TargetGroupIcon} from "../../../assets/img/icon_targetgroup.svg";
import {ReactComponent as TimeConsumptionIcon} from "../../../assets/img/icon_timeconsumption.svg";
import {ReactComponent as MarkerWhiteIcon} from "../../../assets/img/icon_marker_white.svg";
import {ReactComponent as FacbookIcon } from "../../../assets/img/icon_facebook.svg";
import {ReactComponent as TwitterIcon} from "../../../assets/img/icon_twitter.svg";
import {ReactComponent as ScoreIcon } from "../../../assets/img/icon_score.svg";
import {ReactComponent as CorrectIcon } from "../../../assets/img/fig_correct.svg";
import {ReactComponent as NextIcon  } from "../../../assets/img/icon_next.svg";
import {ReactComponent as HighScoreIcon } from "../../../assets/img/icon_highscore.svg";
import {ReactComponent as ReturnIcon  } from "../../../assets/img/icon_return.svg";
import {ReactComponent as WrongIcon  } from "../../../assets/img/fig_wrong.svg";
import {ReactComponent as HomeIcon  } from "../../../assets/img/home_black.svg";
import { ReactComponent as LogoWhite } from "../../../assets/img/logo_white.svg";
import { ReactComponent as LogoBlack } from "../../../assets/img/logo_black.svg";

export type IconType = 'targetGroup' | 'scoreIcon' | 'correctIcon' |
    'nextIcon' | 'markerWhite' | 'timeConsumption' | 'twitter' | 'facebook' |
    'treasure' | 'gps' | 'user' | 'close' | 'back' | 'star' | 'highScoreIcon' |
    'returnIcon' | 'wrongIcon' | 'logoWhite' | 'logoBlack' | 'homeIcon';

export interface IconProps {
    icon: IconType;
}

const renderIcon = (icon: IconType) => {
    switch (icon) {
        case 'gps':
            return <GpsIcon/>;
        case 'logoWhite':
            return <LogoWhite/>;
        case 'logoBlack':
            return <LogoBlack/>;
        case 'highScoreIcon':
            return <HighScoreIcon/>;
        case 'returnIcon':
            return <ReturnIcon/>;
        case 'wrongIcon':
            return <WrongIcon/>;
        case 'user':
            return <UserIcon/>;
        case 'nextIcon':
            return <NextIcon/>;
        case 'homeIcon':
            return <HomeIcon/>;
        case 'correctIcon':
            return <CorrectIcon/>;
        case 'close':
            return <CloseIcon/>;
        case 'scoreIcon':
            return <ScoreIcon/>;
        case 'back':
            return <BackIcon/>;
        case 'star':
            return <StarIcon/>;
        case 'targetGroup':
            return <TargetGroupIcon/>;
        case 'timeConsumption':
            return <TimeConsumptionIcon/>;
        case 'markerWhite':
            return <MarkerWhiteIcon/>;
        case 'twitter':
            return <TwitterIcon/>;
        case 'facebook':
            return <FacbookIcon/>;
        default:
            return <GpsIcon/>;
    }
};

const Icon: FC<IconProps> = ({icon}) => {
    return (
        <>
            {renderIcon(icon)}
        </>
    );
};

export default Icon;