import React from 'react';
import styled from "styled-components";
import Icon from '../Icon/icon';
import Button from "../Button/button";
import Color from "../../../common/classes/Color";

export interface ResultCardProps {
    score?: number;
    onClick?: () => void;
}

const Container = styled.div`
  margin: 4em;
  transform: rotate(1deg);
  animation: slideInFromBottom 0.3s forwards;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 32px;
  box-shadow: 0 12px 0 #000;
  padding: 32px 28px;
  text-align: center;
`;

const Title = styled.h1`
  text-transform: uppercase;
  text-align: center;
  font-weight: 900;
  margin-bottom: 40px;
  font-size: 16px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    margin: 10px 10px 0 10px;
  }
`;

const IconContainer = styled.div`
  position: relative;
`;

const IconLabel = styled.div`
  position: absolute;
  font-weight: 900;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ResultCard: React.FC<ResultCardProps> = ({score, onClick}) => {
    return (
        <Container>
            <Title>
                { score ? 'Riktig svar' : 'Godt gjettet, men det var dessverre feil svar' }
            </Title>
            <Icon icon={score ? "correctIcon" : 'wrongIcon'}/>
            <Footer>
                { score ?
                    <IconContainer>
                        <Icon icon="scoreIcon"/>
                        <IconLabel>+{score}</IconLabel>
                    </IconContainer>
                    : null}
                <Button icon="nextIcon" iconRight bgColor={Color.GREEN} label="Gå videre"/>
            </Footer>
        </Container>
    );
};

export default ResultCard;