import React from 'react';
import styled from "styled-components";
import PostUnit from "../PostUnit/postunit";
import {OverviewPost} from "../../../common/types";

export interface PostListProps {
    bgColor?: string;
    posts?: OverviewPost[];
    ordered?: boolean;
}


const Container = styled.section<PostListProps>`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({bgColor}) => bgColor};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const TaskContainer = styled.ul`
  margin: 30px;
`;

const PostList: React.FC<PostListProps> = (props) => {
    const {posts, ordered} = props;


    return (
        <Container {...props}>
            <TaskContainer>
                {(posts ?? []).map((post: OverviewPost, i) => {
                    return (<PostUnit key={i + 1 + '-' + post.title}
                                      number={i + 1}
                                      ordered={!!ordered}
                                      {...post}
                                      status={post?.scores?.status}
                    />)
                })}
            </TaskContainer>
        </Container>
    );
};

export default PostList;