import React, {FC} from 'react';
import styled from "styled-components";
import Distance from "../Distance/distance";
import Image from "../Image/image";
import treasurePng from "../../../assets/img/fig_treasure@3x.png";

export interface CardProps {
    title?: string;
    description?: string;
    distance?: string;
    owner?: string;
    image?: string;
    bgColor?: string;
    onClick?: () => void;
}

const Container = styled.li<CardProps>`
  display: flex;
  border: 2px solid #000;
  background: ${(props => props?.bgColor)};
  box-shadow: 0 12px 0 0 #000;
  border-radius: 20px;
  padding: 15px 20px;
  width: 315px;
  transition: all .1s;
  transition-property: box-shadow, transform;
  cursor: pointer;
  list-style: none;

  :active {
    transform: translateY(8px);
    box-shadow: 0 4px 0 0 #000;
  }

  img {
    border-radius: 50%;
    margin-right: 20px;
    height: 90px;
    width: 90px;
    padding: 4px;
    border: 1px solid rgba(0, 0, 0, 0.75);
    background: rgba(255, 255, 255, 0.8);
  }
`;

const Content = styled.div`
  float: left;
  text-align: left;
  width: 155px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;


const IconContainer = styled.div`
  > svg {
    width: 95px;
    max-height: 95px;
    float: left;
    margin-right: 15px;
  }
`;

const Title = styled.h2`
  font-size: 1em;
  font-weight: 900;
  margin-bottom: 8px;
`;

const Description = styled.p`
  font-size: .66em;
  font-weight: 900;
  line-height: 140%;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;


const Owner = styled.div`
  display: block;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: .66em;
  margin-top: 8px;
`;


const Card: FC<CardProps> = ({onClick, title, bgColor, description, distance, owner, image}) => {
    const onClickHandler = () => {
        onClick?.();
    }

    return (
        <Container onClick={onClickHandler} bgColor={bgColor}>
            <IconContainer>
                {image ?
                    <Image src={image} alt={title}/>
                    :
                    <Image src={treasurePng} alt={"Illustrasjon av en skatt"}/>
                }
            </IconContainer>
            <Content>
                <Title>{title}</Title>
                <Description>{description}</Description>
                <Distance value={distance}/>
                <Owner>Museene på Bygdøy</Owner>
            </Content>
        </Container>
    );
};

export default Card;