import React from 'react';
import styled from "styled-components";

export interface ScoreUnitProps {
    position?: number;
    name?: string;
    score?: number;
    bgColor?: string;
}


const Container = styled.li<{ bgColor?: string }>`
  display: flex;
  background: ${({bgColor}) => bgColor ? bgColor : '#FFFFFF'};
  border-radius: 16px;
  font-weight: 900;
  border: 1px solid #000;
  box-shadow: 0 6px 0 #000;
  padding: 0 10px;
  > div {
    padding: 7px 0;
    text-align: center;
  }
  > div:first-child {
    width: 50px;
  }
  > div:nth-child(2){
    flex: 1;
    text-align: left;
    padding: 7px 10px;
  }
  > div:last-child {
    width: 70px; 
  }
  > div:not(:first-child) {
    border-left: 2px solid #000000;
  }
`;


const ScoreUnit: React.FC<ScoreUnitProps> = ({
                                                 position,
                                                 name,
                                                 score,
                                                 bgColor
                                             }) => {
    return (
        <Container bgColor={bgColor}>
            <div>
                {position}
            </div>
            <div>
                {name}
            </div>
            <div>
                {score}
            </div>

        </Container>
    );
};

export default ScoreUnit;