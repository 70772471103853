import Settings from "../Settings";
import PersistentStore from "../classes/PersistentStore";
import {ApiSrc} from "../types";

class Api {
    host;
    private HEADER = {
        'Content-Type': 'application/json',
        "x-api-key": "a102fa04-68de-4a16-b8b6-672a1143799f",
        "Authorization": "Bearer " + PersistentStore.get('jwt')
    }

    constructor(src?: ApiSrc) {
        this.host = this.getHost(src);
    }

    public get = async (path: string) => {
        return await fetch(this.host + path, {
            method: 'GET',
            headers: this.HEADER
        })
            .then(res => res.json())
            .catch(err => {
                console.log(err);
            });
    };

    public post = async (path: string, payload?: any) => {
        return await fetch(this.host + path, {
            method: 'POST',
            headers: this.HEADER,
            body: payload ? JSON.stringify(payload) : null
        })
            .then(res => res.json())
            .catch(err => {
                console.log(err);
            });
    }

    public put = async (path: string, payload?: any) => {
        return await fetch(this.host + path, {
            method: 'PUT',
            headers: this.HEADER,
            body: payload ? JSON.stringify(payload) : null
        })
            .then(res => res.json())
            .catch(err => {
                console.log(err);
            });
    }

    public getWithResponse = (path: string) => {
        return fetch(this.host + path, {
            method: 'GET',
            headers: this.HEADER
        })
    }

    private getHost = (src?: ApiSrc): string => {
        switch (src) {
            case 'self':
                return Settings.BASE_HOST_SELF;
            case 'kulturio':
                return Settings.BASE_HOST_KULTURIO;
            default:
                return Settings.BASE_HOST_SELF;
        }
    }

}

export default Api;
