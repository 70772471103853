import React from 'react';
import styled from "styled-components";
import Header from "../../framework/Header/header";
import Footer from "../../framework/Footer/footer";
import {BasePageProps} from "../../../common/types";
import Button from "../../modules/Button/button";
import {useHistory} from "react-router-dom";

export interface PageNotFoundProps extends BasePageProps {

}

const Container = styled.main<{bgColor?: string}>`
   background: ${({bgColor}) => bgColor}; 
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
 display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;


const Title = styled.h1`
    font-weight: 700;
    font-size: 24px;
  margin: 30px 0;
  display: flex;
  align-items: center;
  
`;

const Description = styled.p`
    font-weight: 900;
    font-size: 14px;
  display: flex;
  align-items: center;
  > div {
    margin: 0 15px;
  }
  
`;


const PageNotFound: React.FC<PageNotFoundProps> = ({bgColor, toggleCloseMenu}) => {
    const history = useHistory();
    return(
        <Container bgColor={bgColor}>
            <Header onCloseClick={toggleCloseMenu}/>
            <Content>
                <Title>
                     Fant ikke siden.
                </Title>
                <Description>
                    <Button label="Gå tilbake" onClick={() => {history.goBack()}}/>
                    eller <Button label="Start på nytt" onClick={() => {history.replace('/')}} />
                </Description>
            </Content>
            <Footer fill/>
        </Container>
    )
};

export default PageNotFound;
