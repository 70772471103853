import React, {FC} from 'react';
import styled from "styled-components";
import Icon, {IconType} from "../Icon/icon";


export type ButtonSize = 'small' | 'normal' | 'large';

export interface ButtonProps {
    bgColor?: string;
    color?: string;
    disabled?: boolean;
    label?: string;
    active?: boolean;
    icon?: IconType;
    iconRight?: boolean;
    onClick?: () => void;
    size?: ButtonSize;
    extended?: JSX.Element;
}

const Container = styled.div<ButtonProps>`
  display: flex;
  > button, > aside {
    display: flex;
    box-sizing: content-box;
    justify-content: ${({size}) => size === 'small' ? 'center' : 'space-between'};
    align-items: center;
    padding: ${({size}) => size === 'small' ? '12px' : size === 'large' ? '11px 35px' : '11px 15px'};
    background: ${(props) => props?.bgColor};
    border: 2px solid #000;
    box-shadow: 0 ${({size}) => size === 'large' ? '12' : '6'}px 0 0 #000000;
    transition: all .1s;
    transition-property: box-shadow, transform;
    text-transform: uppercase;
    text-decoration: none;
    cursor: Pointer;
    user-select: none;
    outline: none;
    :active, &.active {
      transform: translateY(${({size}) => size === 'large' ? 8 : 4}px);
      box-shadow: 0 ${({size}) => size === 'large' ? 4 : 2}px 0 0 #000000;
    }
    > svg {
      margin-right: ${({size}) => size === 'small' ? 'unset' : '8px'};
    }
  }
  & button {
    border-radius: ${ ({extended, size}) => extended ? '25px 0 0 25px' : '25px'};
    border-right: ${ ({extended}) => extended ? '1' : '2'}px solid #000;
  }
  & aside {
    border-radius: 0 25px 25px 0;
    border-left: ${ ({extended}) => extended ? '1' : '2'}px solid #000;
    color: #000;
    min-width: 15px;
    font-weight: 900;
    font-size: 16px;
    background: white;
  }
`;

const Main = styled.button<ButtonProps>`
  &.iconRight svg {
    margin: 0 0 0 8px; 
  }
  &:disabled, &[disabled] {
  opacity: 0.5; 
}
`;


const Label = styled.h4<{color?: string}>`
  display: flex;
  justify-self: center;
  align-self: center;
  font-weight: 900;
  margin-top: 1px;
  color: ${ ({color}) => color ? color : '#000'};
  &.big {
    font-size: 32px;
    font-weight: 900;
    text-transform: none;
  }
`;

const Extended = styled.aside`
`;


const Button: FC<ButtonProps> = ({iconRight,
                                     color,
                                     active,
                                     extended,
                                     size,
                                     icon,
                                     onClick,
                                     bgColor,
                                    disabled,
                                     label}) => {
    return (
    <Container extended={extended}
               size={size ?? 'normal'}
               bgColor={bgColor}
               onClick={() => {
        onClick?.()}}>
        <Main className={active ? 'active' : '' + (!!iconRight ? ' iconRight' : '')} disabled={!!disabled}>
            {icon && !iconRight ? <Icon icon={icon}/> : null}
            <Label color={color} className={size === 'large' ? 'big' : ''}>
                {size === 'small' ? null : label}
            </Label>
            {icon && iconRight ? <Icon icon={icon}/> : null}
        </Main>
        {extended ? <Extended>{extended}</Extended> : null}
    </Container>
    );
};

export default Button;