import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {BasePageProps, PuzzleScore, TaskType} from "../../../../../common/types";
import styled from "styled-components";
import Header from "../../../../framework/Header/header";
import Color from "../../../../../common/classes/Color";
import Button from "../../../../modules/Button/button";
import {useParams, useRouteMatch} from "react-router-dom";
import TaskModule from "../../../../modules/Task/task";
import ResultCard from "../../../../modules/ResultCard/resultcard";
import useLocationBlocker from "../../../../hooks/useLocationBlocker";
import Score from "../../../../../common/classes/Score";
import Footer from "../../../../framework/Footer/footer";
import {Store} from "../../../../../Store";
import Settings from "../../../../../common/Settings";
import Position from "../../../../../common/classes/Position";

export interface TaskProps extends BasePageProps {
    media?: string;
    question?: string;
    taskType?: TaskType;
}

const Container = styled.main<{ bgColor?: string }>`
  height: 100%;
  background: ${({bgColor}) => bgColor};
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  margin: 25px 0;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image > div:first-of-type {
    background-color: #000000;
  }

  &.text > div {
    padding: 20px;
    background-color: #FFFF;
    font-weight: 700;
    font-size: 16px;
  }
  &.buttons > div {
    margin-bottom: 25px;
  }
`;

const PageHeader = styled.div`
  margin: 30px 0 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  > h1 {
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
  }
`;


const ResultOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

const Task: React.FC<TaskProps> = ({bgColor, toggleCloseMenu, scoreCard, registerTask}) => {
    const {state} = useContext(Store);
    const { puzzles } = state;
    const {puzzleId, postId, taskId} = useParams<any>();
    const {url} = useRouteMatch();
    const history = useLocationBlocker();
    const [score, setScore] = useState<number | null>();
    const [checkedForValidPost, setCheckedForValidPost] = useState<boolean>(false);
    const {task, haveNextTask, numberOfTasks, currentPuzzle, currentPost}: any = useMemo(() => {
        if (!(puzzleId && postId && taskId && puzzles)) return {};
        const puzzle = puzzles?.find(({id}) => id === parseInt(puzzleId));
        const tasks = puzzle?.posts?.[postId - 1]?.tasks;
        const posts = puzzle?.posts;
        if (!tasks) return {};
        return {
            task: tasks[taskId - 1] ?? {},
            haveNextTask: taskId < (tasks ?? []).length,
            numberOfTasks: tasks?.length ?? 0,
            currentPuzzle: puzzle,
            currentPost: posts?.[postId - 1]
        };
    }, [puzzleId, postId, taskId, puzzles])

    const haveRemainingPosts = useMemo(() => {
        if (!(scoreCard && puzzleId && puzzles)) return true;
        return !Score.isAllPostsComplete(scoreCard.puzzles.find(({id}) => id === parseInt(puzzleId)));
    }, [puzzles, scoreCard, puzzleId]);

    const highScoreScore = useMemo(() => {
        if (!(scoreCard && url)) return 0;
        return Score.parseScoreByUrl(scoreCard, url);
    }, [scoreCard, url])

    const isContext = task?.taskType === 'context';

    const isCompleted = useMemo(() => {
        if (!(scoreCard && currentPost?.id && currentPuzzle?.id && taskId)) return false;
        let isDone = false;
        try {
            scoreCard.puzzles.forEach((puzzle) => {
                puzzle?.id === currentPuzzle?.id && puzzle.posts.forEach((post) => {
                    if (post?.id === currentPost?.id) {
                        let idx = parseInt(taskId) - 1;
                        isDone = post.tasks[idx].isTask && post.tasks[idx].completed;
                    }
                })
            })
        } catch (err) {
            console.warn("Can't check if task is completed. Err: ", err);
        }
        return isDone;
    }, [scoreCard, currentPuzzle, currentPost, taskId])

    // valid by presence and sequential
    const isValidPost: boolean | undefined = useMemo(() => {
        if (!(url && scoreCard && postId && state && currentPuzzle && puzzleId && puzzles)) return undefined;
        const puzzleScore = scoreCard.puzzles.find( (_puzzle: PuzzleScore) => _puzzle.id === currentPuzzle.id);
        const postScore = puzzleScore?.posts?.[postId - 1];
        try {
            if (currentPuzzle?.requirePresence) {
                let postPosition: Position = currentPuzzle.posts?.[postId - 1]?.position;
                let dist = Position.calcDistance(state?.position, postPosition)
                console.log(dist);
                if (typeof dist !== 'number' || dist > Settings.PRESENCE_DISTANCE) return false;
            }
            if (!(puzzleScore && postScore)) return true;
            return !(currentPuzzle?.sequential && !Score.isPostFirstSequential(puzzleScore, postScore));
        } catch (err) {
            return true;
        }
    }, [scoreCard, url, postId, state, puzzleId, puzzles, currentPuzzle])


    const goToNextTask = () => {
        if (!(taskId && url)) return;
        history.replace(`${url.split('/').slice(undefined, -1).join('/')}/${parseInt(taskId) + 1}`);
    }

    const goToOverview = useCallback( () => {
        if (!(postId && url && history)) return;
        history.replace(`${url.split('/').slice(0, 3).join('/')}/overview`);
    }, [history, url, postId]);

    const goToResults = () => {
        if (!(url)) return;
        history.replace(`${url.split('/').splice(0, 3).join('/')}/results`);
    }


    const onFinishTaskHandler = (score: number, skipCard?: boolean) => {
        registerTask && registerTask(currentPuzzle?.id, currentPost?.id, taskId - 1, score);
        setScore(score);
        if (skipCard) nextHandler();
    }

    const nextHandler = (e?: any) => {
        // TODO POINT HANDLING
        setScore(null);
        console.log("nextTask",haveNextTask)
        console.log("remPosts",haveRemainingPosts)
        if (haveNextTask) {
            goToNextTask();
        } else if (haveRemainingPosts) {
            goToOverview();
        } else {
            goToResults();
        }
    }


    const skipTask = () => {
        onFinishTaskHandler(0, true);
        nextHandler();
    }

    useEffect(() => {
        if (checkedForValidPost || typeof isValidPost !== 'boolean') return;
        if (!isValidPost && !isCompleted) {
            goToOverview && goToOverview();
        }
        setCheckedForValidPost(true);
    }, [isValidPost, isCompleted, goToOverview, checkedForValidPost])

    return (
        <Container bgColor={bgColor}>
            <Header backButtonStyle="home" type="score" score={highScoreScore} onCloseClick={toggleCloseMenu}/>
            <PageHeader>
                { !isContext ?
                    <h1>Oppgave {`${taskId}/${numberOfTasks}`}</h1>
                    : null}
            </PageHeader>
            <Content>
                {isCompleted ? <>Du har fullført oppgaven</> :
                    <TaskModule task={task} onFinish={onFinishTaskHandler}/>
                }
            </Content>
            <Content className="buttons">
                { !isCompleted && !isContext ? <Button size="large" bgColor={Color.GREEN} onClick={skipTask} label="Hopp over"/> : null}
                {(isCompleted || isContext) && haveNextTask ?
                    <Button size="large" bgColor={Color.GREEN} onClick={goToNextTask} label="Gå videre"/>
                    : (isCompleted || isContext) && haveRemainingPosts ?
                        <Button size="large" bgColor={Color.GREEN} onClick={goToOverview} label="Gå videre"/>
                        : (isCompleted || isContext) ?
                            <Button size="large" bgColor={Color.GREEN} onClick={goToResults} label="Fullfør"/>
                            : null
                }
            </Content>
            {typeof score === 'number' ?
                <ResultOverlay onClick={nextHandler}>
                    <ResultCard score={score} onClick={nextHandler}/>
                </ResultOverlay>
                : null}
            <Footer fill/>
        </Container>
    );
};

export default Task;