import React from 'react';
import ShadowContainer from "../../../../framework/ShadowContainer/shadowcontainer";
import Image from "../../../Image/image";
import styled from "styled-components";
import {TaskContext} from "../../../../../common/types";


export interface ContextProps {
    task?: TaskContext;
    onFinish?: (score: number) => void;
}

const Content = styled.section`
  margin: 25px 0;
  align-self: center;
  width: 311px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.image > div {
    background-color: #000000;
  }

  &.text > div {
    padding: 20px;
    background-color: #FFFF;
    font-weight: 700;
    font-size: 16px;
  }

  &.image {
    min-height: 207px;
  }

  &.image img {
    width: 311px;
  }
`;

const Context: React.FC<ContextProps> = ({task}) => {
    return (
        <div>
            {task?.image ?
                <Content className={'image'}>
                    <ShadowContainer>
                        <Image
                            src={task.image}
                            sizes={{
                                large: '20vw',
                                medium: '50vw',
                                small: '100vw'
                            }}
                        />
                    </ShadowContainer>
                </Content>
                : null}
            <Content className={'text'}>
                <ShadowContainer>
                    <p>{task?.body}</p>
                </ShadowContainer>
            </Content>
        </div>
    );
};

export default Context;