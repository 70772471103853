import React, {useContext, useEffect, useMemo} from 'react';
import Button from "../../../modules/Button/button";
import styled from "styled-components";
import Header from "../../../framework/Header/header";
import Map from "../../../modules/Map/map";
import PostList from "../../../modules/PostList/postlist";
import Color from "../../../../common/classes/Color";
import {Route, Switch, useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {BasePageProps} from "../../../../common/types";
import Score from "../../../../common/classes/Score";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Store} from "../../../../Store";
import Position from "../../../../common/classes/Position";
import Settings from "../../../../common/Settings";
import Footer from "../../../framework/Footer/footer";
import usePuzzleCompleted from "../../../hooks/usePuzzleCompleted";


export interface PostsProps extends BasePageProps {
}


const Container = styled.main<PostsProps>`
  height: 100%;
  background: ${({bgColor}) => bgColor};
  display: flex;
  flex-direction: column;
`;

const PageHeader = styled.div`
  margin: 30px 0 0;
  text-align: center;
  > h1, h2 {
    font-size: 16px;
  }
  > h1 {
    font-weight: 900;
  }

  > h2 {
    font-weight: 400;
  }
`;

const Navigation = styled.nav`
  display: flex;
  justify-content: center;
  align-self: center;
  > div:not(:first-of-type) {
    margin-left: 15px;
  }
`;

const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  &.subPage {
    flex: 1 1 100%;
    display: flex;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    > div {
      width: 100%;
      height: 100%;
      position: relative;
      > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
  &.nav {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
`;

const Overview: React.FC<PostsProps> = (props) => {
    const { state} = useContext(Store);
    const { scoreCard } = props;
    const history = useHistory();
    const location = useLocation();
    const {path, url} = useRouteMatch();
    const { puzzles } = state;
    const { puzzleId } = useParams<any>();
    const currentPuzzle = useMemo( () => {
        if (!(puzzles && puzzleId)) return;
        return puzzles.find( ({id}) => id === parseInt(puzzleId));
    }, [puzzles, puzzleId]);
    const puzzleCompleted = usePuzzleCompleted();

    const { posts, seqRequired } = useMemo( () => {

        const seqRequired: boolean = !!currentPuzzle?.sequential ?? Settings.SEQUENTIAL;
        const maxThreshold = currentPuzzle?.requirePresence ? Settings?.PRESENCE_DISTANCE : Number.POSITIVE_INFINITY;
        if (!(puzzleId && currentPuzzle)) return {posts: [], seqRequired: false};
        const _posts = currentPuzzle?.posts ?? [];
        for (let i = 0; i < _posts.length; i++){
            const _dist = Position.calcDistance(state?.position, _posts[i].position);
            const sufficientProximity = typeof _dist === 'number' ? _dist <= maxThreshold : false;
            const isFirstUncompletedPost = _posts.find( (x) => x?.scores?.status !== 'done') === _posts[i];
            _posts[i].scores = scoreCard?.puzzles.find( ({id}) => id === parseInt(puzzleId))?.posts[i] ?? [];
            if (Score.isPostComplete(_posts[i].scores)){
                _posts[i].scores.status = 'done';
                continue;
            }
            if (!(sufficientProximity && ((seqRequired && isFirstUncompletedPost) || !seqRequired))){
                _posts[i].scores.status = 'locked';
                continue;
            }
            _posts[i].scores.status = 'unlocked';
        }
        return {
            posts: [..._posts],
            seqRequired
        };
    }, [puzzleId, scoreCard, state, currentPuzzle]);

    const tasksCompleted = useMemo( () => {
       if (!scoreCard) return 0;
       const puzzle = scoreCard?.puzzles?.find( ({id}) => id === parseInt(puzzleId))
       return puzzle ? Score.getNumberOfCompletedTasks(puzzle) : 0;
    }, [scoreCard, puzzleId]);

    const score = useMemo( () => {
       if (!(props?.scoreCard && url)) return 0;
       return Score.parseScoreByUrl(props.scoreCard, url);
    }, [props, url]);

    useEffect( () => {
        if (puzzleCompleted && history && url){
            history.replace(`${url.split('/').slice(0,3).join('/')}/results`);
        }
    }, [puzzleCompleted, history, url])

    return (
        <Container {...props}>
            <Header type="score" score={score} onCloseClick={props?.toggleCloseMenu}/>
            <Content>
                <PageHeader>
                    <h1>
                        Skattejakt på bygdøy
                    </h1>
                    <h2>
                        { `${tasksCompleted} av ${posts.length} funnet`}
                    </h2>
                </PageHeader>
            </Content>
            <Content className="nav">
                <Navigation>
                    <Button label={'Kart'} bgColor={Color.PURPLE} color={Color.WHITE}
                            active={!history.location.pathname.includes('list')}
                            onClick={() => {
                                history.replace(`${url}/map`)
                            }}
                    />
                    <Button label={'Liste'} bgColor={Color.PURPLE} color={Color.WHITE}
                            active={history.location.pathname.includes(('list'))}
                            onClick={() => {
                                history.replace(`${url}/list`)
                            }}
                    />
                </Navigation>
            </Content>
            <Content className={'subPage'}>
                <TransitionGroup>
                    <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={300}
                    >
                        <Switch location={location}>
                            <Route path={`${path}/list`} exact>
                                <PostList bgColor={Color.YELLOW_LIGHT} posts={posts} ordered={seqRequired}/>
                            </Route>
                            <Route path={`${path}/`}>
                                    <Map posts={posts} ordered={seqRequired}/>
                            </Route>
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </Content>
            <Footer/>
        </Container>
    );
};

export default Overview;