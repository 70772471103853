import React, {useEffect, useMemo, useState} from 'react';
import {IndexIdentifiers, PersonalityTask, PlayerAnswer} from "../../../../../common/types";
import styled from "styled-components";
import Button from "../../../Button/button";
import Color from "../../../../../common/classes/Color";
import Image from "../../../Image/image";
import placeholderMedia2 from "../../../../../assets/img/placeholder_media2.jpg";
import ShadowContainer from "../../../../framework/ShadowContainer/shadowcontainer";

export interface PersonalityTestProps {
    task?: PersonalityTask;
    onFinish?: (score: number, skipCard: boolean) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  margin-bottom: 30px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
`;

const SubTitle = styled.h2`
  background: #FFF;
  padding: 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

const ResultContainer = styled.div<{ bgColor?: string }>`
  width: 100%;
  box-sizing: content-box;
  padding: 25px;
  background: ${({bgColor}) => bgColor ?? '#FFFFFF'};
  font-weight: 900;
  font-size: 30px;
`;


const BodyContainer = styled.p`
  width: 311px;
  background: #FFF;
  padding: 25px;
  box-sizing: border-box;
  font-weight: 900;
  text-align: center;
`;

const Content = styled.div`
  width: 311px;
  display: flex;
  flex-direction: column;


  &.taskHeader > div {
    flex-direction: column;
    margin-bottom: 25px;
    background: #000;
  }

  &.buttons > div:not(:first-of-type) {
    margin-top: 20px;
  }

  &.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.buttons button {
    text-transform: revert;
    font-size: 16px;
    text-align: left;
  }

  &.buttons.answers > * {
    align-self: stretch;

    button {
      width: 100%;
    }
  }

  &.buttons h4 {
    font-weight: 700;
  }

  &.result, &.body {
    margin: 40px 0;
    align-self: normal;
  }

  &.image.circle img {
    height: 250px;
    width: 250px;
  }

  &.taskHeader img {
    width: 100%;
  }
`;

const PersonalityTest: React.FC<PersonalityTestProps> = ({task, onFinish}) => {
    const [curQuestionIdx, setCurQuestionIdx] = useState<number>();
    const [playerAnswers, setPlayerAnswers] = useState<PlayerAnswer>({})
    const [result, setResult] = useState<{ title?: string, body?: string, image?: string }>();
    const answers: object = useMemo(() => {
        if (!(typeof curQuestionIdx === 'number' && task)) return {};
        const _answers = task?.questions?.[curQuestionIdx]?.answers;
        return _answers ? _answers : {};
    }, [curQuestionIdx, task])

    const goToNextQuestion = () => {
        if (typeof curQuestionIdx === 'number' && task?.questions?.[curQuestionIdx + 1]) {
            setCurQuestionIdx((cur) => typeof cur === 'number' ? cur + 1 : 0);
            return;
        }
        if (!task?.summary) return;
        const winningLetter = Object.entries(playerAnswers).reduce((item, last) => item[1] > last[1] ? item : last)[0];
        const res = task && winningLetter in task?.summary ? task.summary[winningLetter as IndexIdentifiers] : {};
        setResult({
            title: res?.title ?? '',
            body: res?.body ?? '',
            image: res?.image ?? ''
        })
    }
    const qSelectHandler = (key: string) => {
        setPlayerAnswers((prev) => {
            let tmp = {...prev};
            if (key in tmp) {
                tmp[key] += 1;
                return tmp;
            }
            tmp[key] = 1;
            return tmp;
        })
        goToNextQuestion();
    }

    useEffect(() => {
        if (!task) return;
        if (task?.questions?.[0].answers) {
            setCurQuestionIdx(0);
            const {answers: _answers} = task.questions[0];
            let initializePlayerAnswers: PlayerAnswer = {};
            for (let key of Object.keys(_answers)){
                initializePlayerAnswers[key] = 0;
            }
            setPlayerAnswers(initializePlayerAnswers);
        }
    }, [task]);

    console.log(playerAnswers);

    return (
        <Container>
            {result ?
                <>
                    <Content className="result">
                        <ShadowContainer>
                            <ResultContainer bgColor={Color.YELLOW}>
                                {result.title}
                            </ResultContainer>
                        </ShadowContainer>
                    </Content>
                    <Content className={"image circle"}>
                        <ShadowContainer circle shadowLength={6}>
                            {result?.image ?
                                <Image src={result?.image}
                                       sizes={{small: '100vw', medium: '50vw', large: '25vw'}}
                                       alt="hvem er du?"/>
                                : <img src={placeholderMedia2} alt="hvem er du?"/>}
                        </ShadowContainer>
                    </Content>
                    <Content className="body">
                        <ShadowContainer>
                            <BodyContainer>
                                {result.body || 'Her er det body text'}
                            </BodyContainer>
                        </ShadowContainer>
                    </Content>
                    <Content className="buttons">
                        <Button size="large" label="Gå videre" bgColor={Color.GREEN} onClick={() => onFinish?.(10, true)}/>
                    </Content>
                </>
                :
                <>
                    <Content className="title">
                        <Title>{task?.title}</Title>
                    </Content>
                    <Content className="taskHeader">
                        <ShadowContainer>
                            {task?.image ?
                                <Image src={task?.image}
                                       sizes={{small: '100vw', medium: '50vw', large: '25vw'}}
                                       alt="hvem er du?"/>
                                : <img src={placeholderMedia2} alt={"hvem er du?"}/>}
                            {typeof curQuestionIdx === 'number' && task?.questions?.[curQuestionIdx]?.question ?
                                <SubTitle>{task.questions[curQuestionIdx].question}</SubTitle>
                                : null}
                        </ShadowContainer>
                    </Content>
                    <Content className="buttons answers">
                        {Object.entries(answers).sort( (a, b) => a[0] > b[0] ? 1 : -1).map(([key, value], i) => {
                            return <Button onClick={() => {
                                qSelectHandler(key)
                            }} label={value.answer} key={i + '-' + value} bgColor={Color.PURPLE} color={Color.WHITE}/>;
                        })}
                    </Content>
                </>}
        </Container>
    );
};

export default PersonalityTest;