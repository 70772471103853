import React from 'react';
import styled from "styled-components";
import {LetterType} from "../../../common/types";


export interface LetterPayload {
    letter?: string;
    idx?: number;
    name?: string;
}

export interface LetterBoxProps extends LetterPayload {
    type?: LetterType; // defaults to 'answer';
    color?: string;
    bgColor?: string;
    onChange?: (payload: LetterPayload) => void;
    onClick?: (payload: LetterPayload) => void;
}


const Container = styled.li<LetterBoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  background-color: ${({bgColor}) => bgColor ?? '#FFFFFF'};
  color: ${({color}) => color ?? '#000'};
  width: 40px;
  height: 38px;
  text-align: center;
  font-weight: 900;
  font-size: 1.5em;
  float: left;
  border: 2px solid #000;
  box-shadow: 0 6px 0 #000;
  border-radius: 12px;
  cursor: pointer;
  transition: all .1s;
  transition-property: box-shadow, transform;
  &.alternative {
    width: 50px;
    height: 48px;
  }
  &:active {
    box-shadow: 0 2px #000;
    transform: translateY(4px);
  }
`;

const LetterBox: React.FC<LetterBoxProps> = (props) => {
    const { letter, type, color, bgColor, name, idx, onClick } = props;

    const onClickHandler = (e: any) => {
        onClick?.({
            name,
            letter,
            idx
        })
    }

    return (
        <Container onClick={onClickHandler}
                   bgColor={bgColor}
                   color={color}
                   value={letter}
                   className={type === 'alternative' ? 'alternative' : 'answer'}>
                {letter}
        </Container>
    );
};

export default LetterBox;