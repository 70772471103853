import React, {useCallback, useContext, useMemo} from 'react';
import styled from "styled-components";
import Button from "../Button/button";
import Color from "../../../common/classes/Color";
import Numbering from "../Numbering/numbering";
import {useHistory, useRouteMatch} from "react-router-dom";
import Distance from "../Distance/distance";
import Position from "../../../common/classes/Position";
import {Store} from "../../../Store";

export type PostStatus = 'unlocked' | 'locked' | 'done';

export interface PostUnitProps {
    number?: number;
    title?: string;
    description?: string;
    ordered?: boolean;
    position?: Position;
    status?: PostStatus;
}

const Container = styled.li`
  position: relative;
  display: flex;
  height: 150px;
  
  > div:first-child {
    flex: 0 0 auto;
  }

  &:not(:last-of-type)::before {
    content: "";
    height: 100%;
    border: 2px dashed #000;
    top: 2px;
    left: 22px;
    position: absolute;
    opacity: .3;
  }

  > div:last-child {
    flex: 0 0 auto;
    align-self: flex-start;
  }

`;


const Content = styled.div`
  flex: 1 1 auto;
  max-width: 250px;

  > h2 {
    font-weight: 900;
    font-size: 1.2em;
  }

  > p {
    font-weight: 900;
    font-size: .8em;
    line-height: 130%;
    margin: 10px 0;
  }
`;

const PostUnit: React.FC<PostUnitProps> = (props) => {
    const history = useHistory();
    const {state} = useContext(Store);
    const {url} = useRouteMatch();
    const {status, number, position} = props;
    const distanceString = useMemo(() => {
        if (!(position && state?.position)) return '';
        const meters = Position.calcDistance(position, state.position)
        return Position.convertMetersToFinalString(meters);
    }, [position, state])

    const toTaskClick = useCallback(() => {
        console.log("123");
        console.log(url, number, history);
        if (!(url && number && history)) return;
        const link = url.split('/').slice(0, 3).join('/') + '/post/' + number + '/task/1';
        history.push(link);
    }, [url, number, history]);


    return (
        <Container>
            <Numbering type={status}>{number}</Numbering>
            <Content>
                <h2>{props?.title}</h2>
                <p>{props?.description}</p>
                {status === 'unlocked' ?
                    <Button onClick={toTaskClick} bgColor={Color.GREEN} label="Gå til post"/> : null}
            </Content>
            {distanceString ?
                <Distance value={distanceString}/>
                : null}
        </Container>
    );
};

export default PostUnit;