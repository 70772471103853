import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import history from "./history";
import './App.css';
import Loading from "./components/framework/Loading/loading";
import Color from "./common/classes/Color";
import useFetchPuzzle from "./components/hooks/useFetchPuzzle";
import {clearInterval, setInterval} from "timers";
import CloseOverlay from "./components/framework/CloseOverlay/closeoverlay";
import styled from "styled-components";
import useScoreCard from "./components/hooks/useScoreCard";
import usePosition from "./components/hooks/usePosition";
import {Store} from "./Store";
import Routes from "./routes";


const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const App = () => {
    const {dispatch} = useContext(Store);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [closeMenuOpen, setCloseMenuOpen] = useState<boolean>(false);
    const [progressValue, setProgressValue] = useState<number>(0);
    const position = usePosition();
    const puzzles = useFetchPuzzle();
    const {scoreCard, registerTask, updateCard} = useScoreCard(puzzles);
    const dispatchRef = useRef(dispatch);

    const toggleCloseMenu = useCallback(() => {
        setCloseMenuOpen((prev) => !prev);
    }, [])

    const pageProps = useMemo(() => {
        return {
            puzzles: puzzles ? puzzles : {},
            toggleCloseMenu,
            scoreCard,
            registerTask,
            updateCard
        }
    }, [puzzles, toggleCloseMenu, scoreCard, registerTask, updateCard])

    const closeMenu = useCallback(() => {
        setCloseMenuOpen(false);
    }, []);

    const onClose = useCallback(() => {
        window.location.href = 'https://www.kulturpunkt.org';
    }, [])

    useEffect(() => {
        dispatchRef.current?.(
            {type: 'SET_POSITION', payload: position}
        )
        //position && dispatchPosition(position);
    }, [position])


    useEffect( () => {
        dispatchRef.current?.(
            {type: 'SET_PUZZLES', payload: puzzles}
        )
    }, [puzzles]);

    useEffect( () => {
       dispatchRef.current?.(
           {type: 'SET_SCORECARD', payload: scoreCard}
       )
    }, [scoreCard])

    useEffect(() => {
        document.title = 'Skattejakt';
        setIsLoading(true);
        let interval = setInterval(() => {
            setProgressValue((p: number) => {
                if (p < 100) {
                    return p + 1;
                }
                clearInterval(interval);
                setIsLoading(false);
                return 100;
            });
        }, 50);
        return () => {
            document.title = '';
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {
        if (puzzles) {
            setProgressValue(100);
        }
    }, [puzzles])

    return (
        <div className="App">
            {isLoading
                ? <Loading progressValue={progressValue} bgColor={Color.PURPLE}/> :
                <Router history={history}>
                    <Switch>
                        <Route path="*">
                            <Routes {...pageProps}/>
                        </Route>
                    </Switch>
                </Router>
            }
            {
                closeMenuOpen ?
                    <Overlay>
                        <CloseOverlay onClose={onClose} onStay={closeMenu}/>
                    </Overlay> : null
            }
        </div>
    );
}

export default App;
