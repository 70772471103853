import React from 'react';
import styled from "styled-components";
import useDeviceQuery from "../../hooks/useDeviceQuery";
import Icon from "../../modules/Icon/icon";


export interface FooterProps {
    fill?: boolean;
}

const Container = styled.footer`
  margin: 75px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;


const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > div:first-of-type {
    display: flex; 
    justify-content: flex-start;
  }
  > div:last-of-type {
    display: flex;
    justify-content: flex-end;
  }
`;

const Fill = styled.div`
  flex: 1;
`;

/**
 *
 * @param fill - Fill before for flex at end
 * @constructor
 */
const Footer: React.FC<FooterProps> = ({fill = false}) => {
    const isMobile = useDeviceQuery('mobile');

    return (
        <>
            { isMobile ? null :
                <Container>
                    {fill ?
                        <Fill/>
                        : null}
                    <Content>
                        { /*<Button label="Instillinger" disabled/> */ }<></>
                        <Icon icon="logoBlack"/>
                  { /*      <Button label="Hjelp ?" disabled/>*/ } <></>
                    </Content>
                </Container>
            }
        </>
    );
};

export default Footer;