import {FC, useEffect, useMemo} from 'react';
import { codeToToken } from "@kulturit/authentication";
import useQuery from "../../hooks/useQuery";
import {useHistory} from "react-router-dom";
import Settings from "../../../common/Settings";



const LoginCallback: FC = () => {
    const q = useQuery();
    const history = useHistory();
    const [ code, state ] = useMemo( () => {
        try{
            return [q.get('code'), q.get('state')];
        } catch (err){
            return [null,  null]
        }
    }, [q])

    useEffect( () => {
        if (!(code && state && history)) return;
        codeToToken({
            code,
            state,
            apiGateway: Settings.getApiGatewayUrl(),
            onSuccess: () => {
                console.log("Successfully received token")
                history.replace('/');
            },
            onError: () => {
                console.error("Attempt to receive token failed!")
                history.replace('/');
            }
        })

    }, [code, state, history])

    console.log(q.get('code'), q.get('state'));
    return(<div>
    asd
    </div>)
};

export default LoginCallback;