class Settings {
    /** @member {BASE_HOST_KULTURIO} - API root kulturio url */
    static BASE_HOST_KULTURIO: string = "https://api.kulturio.org/portal/api";
    /** @member {BASE_HOST_SELF} - API root self (skattejakt) */
    static BASE_HOST_SELF: string = "/api";
    /** @member {SEQUENTIAL} - Posts need to be unlocked in sequential order */
    static SEQUENTIAL: boolean = false;
    /** @member {PRESENCE_DISTANCE} - Max distance in meters from user to post that unlocks post */
    static PRESENCE_DISTANCE: number = 100;
    static GOOGLE_MAP_API_KEY = "AIzaSyA0UKwjqalA0BdK5GBh8RPR9HJjtifWLAg";
    static GOOGLE_MAP_OPTIONS: any = {
        zoom: 14,
        center: { lat: 59.9031489, lng: 10.6881607 },
        mapTypeId: "roadmap",
        disableDefaultUI: true,
        styles: [{"elementType":"geometry","stylers":[{"color":"#f5f5f5"}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"elementType":"labels.text.stroke","stylers":[{"color":"#f5f5f5"}]},{"featureType":"administrative.land_parcel","elementType":"labels.text.fill","stylers":[{"color":"#bdbdbd"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"poi.park","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#dadada"}]},{"featureType":"road.highway","elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"transit.station","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#c9c9c9"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]}]
    }
    static getApiGatewayUrl = (): string => {
        const host = window.location.host ?? 'localhost';
        if (host.includes('localhost') || host.includes('dev-skattejakt')){
            return "https://beta-apigateway.dimu.org";
        }
        return "https://apigateway.dimu.org";
    }
}

export default Settings;