import React, {useEffect, useMemo, useState} from 'react';
import {WordGuessTask} from "../../../../../common/types";
import styled from "styled-components";
import LetterBox, {LetterPayload} from "../../../LetterBox/letterbox";
import Color from "../../../../../common/classes/Color";
import ShadowContainer from "../../../../framework/ShadowContainer/shadowcontainer";
import Image from "../../../Image/image";

export interface WordGuessProps {
    task?: WordGuessTask;
    onFinish?: (score: number) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AnswerContainer = styled.ul`
  margin: 25px;
  display: flex;
  align-self: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  > li:last-of-type::after{
    content: '';
    position: absolute;
    flex: 1;
  }
  &.small {
    align-self: center;
    justify-content: center;
    gap: 5px;
  }
`;

const ChoicesContainer = styled.ul`
  margin: 1.5em 1em;
  max-width: 25em;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 5px;

  > li {
    margin: 5px;
  }
`;


const ImageCaption = styled.div`
  font-size: 16px;
  width: 100%;
  font-weight: 700;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-top: 2px solid black;
`;

const Content = styled.section`
  width: 311px;
  display: flex;

  &.image {
    display: flex;
    flex-direction: column;
  }

  &.image > div:first-of-type {
    border-radius: 50%;
    min-height: 300px;
  }

  &.image > div:last-of-type {
    display: flex;
    box-shadow: 0 6px 0 #000;
    background: #000;
    transform: translateY(-20px);
  }

  &.image img {
    width: 311px;
  }
`;

const isFilledArray = (arr: string[]) => {
    if (arr.length === 0) return false;
    for (let i = 0; i < arr.length; i++) {
        if (!arr[i]) {
            return false;
        }
    }
    return true;
}


const WordGuess: React.FC<WordGuessProps> = ({task, onFinish}) => {
    const [letterChoices, setLetterChoices] = useState<string[]>([]);
    const [answer, setAnswer] = useState<string[]>([]);

    const answerSize = useMemo(() => {
        if (answer.length < 7) return 'small';
        if (answer.length < 11) return 'normal';
        return 'big';
    }, [answer])

    const letterOnChangeHandler = (payload: LetterPayload) => {
        const {idx, name, letter} = payload;
        if (name === 'answer' && typeof idx === 'number') {
            setAnswer((prev: string[]) => {
                let tmp = prev;
                if (Array.isArray(tmp) && (tmp.length > idx) && letter) {
                    tmp[idx] = letter;
                }
                return [...tmp];
            })
        }
    }

    const onLetterClick = (payload: LetterPayload) => {
        const {letter, idx, name} = payload;
        if (!(letter && name && typeof idx === 'number')) return;
        if (name === 'choices') {
            setAnswer((prev) => {
                let tmp = [...prev];
                for (let i = 0; i < tmp.length; i++) {
                    if (tmp[i] === "") {
                        tmp[i] = letter;
                        setLetterChoices((prev) => {
                            let tmp = [...prev];
                            tmp[idx] = "";
                            return tmp;

                        });
                        break;
                    }
                }
                return tmp;
            })
        } else if (name === 'answer') {
            setAnswer((prev) => {
                let tmp = [...prev];
                tmp[idx] = "";
                return tmp;
            })
            setLetterChoices((prev) => {
                let tmp = [...prev];
                for (let i = 0; i < tmp.length; i++) {
                    if (!tmp[i]) {
                        tmp[i] = letter;
                        break;
                    }
                }
                return tmp;
            })
        }
    }

    useEffect(() => {
        if (!task?.answer) return;
        const scramble = (originalPartial: string[]) => {
            const getRandomCharArr = (length: number): string[] => {
                let res = [];
                for (let i = 0; i < length; i++) {
                    res.push(Math.random()
                        .toString(36)
                        .replace(/[^a-z]+/g, '')
                        .substr(0, 1)
                        .toUpperCase());
                }
                return res;
            }
            let randList = getRandomCharArr(5);
            let choices = [...originalPartial, ...randList];
            for (let i = choices.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1));
                let temp = choices[i];
                choices[i] = choices[j];
                choices[j] = temp;
            }
            setLetterChoices(choices);
        }
        let _a: string[] = [];
        let _b = Array.from(task.answer?.toUpperCase().slice(0));
        let _answer: string[] = [];
        _a.forEach((char: string) => _answer.push(char));
        _b.forEach(() => _answer.push(''));
        setAnswer(_answer);
        scramble(_b);
    }, [task]);

    useEffect(() => {
        if (!(isFilledArray(answer) && task?.answer)) return;
        // game is complete - comparing result
        setAnswer([]);
        if (answer.join('') === task?.answer.toUpperCase()) {
            onFinish?.(task?.score ?? 0);
            return;
        }
        onFinish?.(0);
    }, [answer, task, onFinish]);

    return (
        <Container>
            <Content className={'image'}>
                <ShadowContainer>
                    {task?.image ?
                        <Image
                            src={task.image}
                            sizes={{
                                large: '20vw',
                                medium: '50vw',
                                small: '100vw'
                            }}
                        />
                        : null}
                </ShadowContainer>
                <ShadowContainer>
                    <ImageCaption>
                        {task?.question}
                    </ImageCaption>
                </ShadowContainer>
            </Content>
            <AnswerContainer className={answerSize}>
                {(answer ?? []).map((char: string, i) => {
                    return (<LetterBox
                        onChange={letterOnChangeHandler}
                        letter={char}
                        idx={i}
                        onClick={onLetterClick}
                        name="answer"
                        key={'answer-' + char + '-' + i}
                    />)
                })}
            </AnswerContainer>
            <ChoicesContainer>
                {(letterChoices ?? []).map((char: string, i) => {
                    return (<LetterBox
                        onChange={letterOnChangeHandler}
                        idx={i}
                        name="choices"
                        type="alternative"
                        bgColor={Color.PURPLE}
                        color={Color.WHITE}
                        letter={char}
                        onClick={onLetterClick}
                        key={'choices-' + char + '-' + i}
                    />)
                })}
            </ChoicesContainer>
        </Container>
    );
};

export default WordGuess;