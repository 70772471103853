import React, {useMemo} from 'react';
import {PostStatus} from "../PostUnit/postunit";
import styled from "styled-components";
import Color from "../../../common/classes/Color";


export interface NumberingProps {
    type?: PostStatus;
}

type Colors = {
    [k: string]: string;
}

const Container = styled.div<{colors?: Colors}>`
  background: #FFFFFF;
  color: #000;
  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 36px;
  width: 48px;
  height: 48px;
  display: block;
  text-align: center;
  font-weight: 900;
  font-size: 1.2em;
  padding-top: 13px;
  float: left;
  margin-right: 16px;
  position: relative;
  &.locked {
    background: white;
    box-shadow: 0 6px 0 0 #000000;
  }
  &.unlocked {
    background: ${({colors}) => colors?.PURPLE };
    color: ${({ colors }) => colors?.WHITE };  
    box-shadow: 0 0 0 5px rgba(255,255,255, 1)
  }
  &.done {
    border: 2px dashed #000000;
    background: ${({colors}) => colors?.YELLOW_LIGHT };
  }
`;

const Numbering: React.FC<NumberingProps> = ({ type , children}) => {

    const colors = useMemo( () => {
        let colors: any = {};
        Object.entries(Color).forEach( ([key, value]) => {
            colors[key] = value;
        })
        return colors;
    }, [])

    return (
        <Container className={type} colors={colors}>
            {children}
        </Container>
    );
};

export default Numbering;