import React from 'react';
import {QuizAnswer, QuizTask} from "../../../../../common/types";
import styled from "styled-components";
import Button from "../../../Button/button";
import Color from "../../../../../common/classes/Color";
import ShadowContainer from "../../../../framework/ShadowContainer/shadowcontainer";
import Image from "../../../Image/image";

export interface QuizProps {
    task?: QuizTask;
    onFinish?: (score: number) => void;
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.ul`
  width: 311px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 315px;
  > div {
    margin: 10px;
    display: inline;
  }
  button {
    width: 100%;
    text-transform: revert;
    text-align: left;
    box-sizing: border-box;
  }
  &.buttons > * {
    align-self: normal;
  }
  
  &.image {
    margin-bottom: 30px;
  }
`;

const ImageCaption = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;


const Quiz: React.FC<QuizProps> = ({task, onFinish}) => {

    const answerClickHandler = (answer: QuizAnswer) => {
        if (answer?.correct){
            onFinish?.(task?.score ?? 0);
            return;
        }
        onFinish?.(0);
    }

    return (
        <Container>
            <Content className={'image'}>
                <ShadowContainer>
                    {task?.image ?
                        <Image
                            src={task.image}
                            sizes={{
                                large: '20vw',
                                medium: '50vw',
                                small: '100vw'
                            }}
                            alt="quiz"
                        />
                        : null
                    }
                    <ImageCaption>{task?.question}</ImageCaption>
                </ShadowContainer>
            </Content>
            <Content className="buttons">
                {  (task?.answers ?? []).map( (answer: any) => {
                    return(<Button onClick={() => {answerClickHandler(answer)}} label={answer?.answer} key={answer?.answer} bgColor={Color.PURPLE} color={Color.WHITE}/>);
                })}
            </Content>
        </Container>
    );
};

export default Quiz;