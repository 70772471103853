import {PostScore, PuzzleScore, ScoreCard} from "../types";
import {PostStatus} from "../../components/modules/PostUnit/postunit";

class Score {

    static resetScoreCard = (card: ScoreCard,) => {

    }

    static resetScoreCardPuzzle = (card: ScoreCard, puzzleId: number) => {
        let tmpCard = {...card};
        let puzzle = tmpCard?.puzzles?.filter( (_puzzle: PuzzleScore) => _puzzle.id === puzzleId)?.[0];
        if (!puzzle) return card;
        puzzle.score = 0;
        puzzle.posts.forEach((post) => {
            post.tasks = post.tasks.map((task) => {
                return {
                    ...task,
                    completed: !task.isTask
                }
            })
        })
        return tmpCard;
    }

    static parseScoreByUrl = (card: ScoreCard, url: string) => {
        if (!(url && Array.isArray(card.puzzles))) return 0;
        try {
            let _id = parseInt(url.split('/')[2]);
            return card?.puzzles?.find( ({id}) => id === _id)?.score ?? 0;
        } catch (err) {
            console.warn("Can't parse puzzle score from puzzle. Err: ", err);
        }
        return 0;
    }

    static getNumberOfCompletedTasks = (puzzle: PuzzleScore) => {
        let count = 0;
        puzzle?.posts?.forEach( (post) => {
            if (Score.isPostComplete(post)){
                count += 1;
            }
        })
        return count;
    }

    static getPostStatus = (post: PostScore): PostStatus => {
        if (Score.isPostComplete(post)){
            return 'done';
        }
        return 'unlocked';
    }

    static isPostComplete = (post?: PostScore) => {
        if (!post?.tasks) return false;
        for (let i = 0; i < post.tasks.length; i++){
            if (!post.tasks[i].completed){
                return false;
            }
        }
        return true;
    }

    static isAllPostsComplete = (puzzle?: PuzzleScore) => {
        let posts = puzzle?.posts;
        if (!Array.isArray(posts)) return false;
        for (let i = 0; i < posts.length; i++){
            if (!Score.isPostComplete(posts[i])) return false;
        }
        return true;
    }

    static isPostFirstSequential = (puzzle: PuzzleScore, post: PostScore): boolean => {
        for (const _post of puzzle?.posts){
            if (Score.isPostComplete(_post)) continue;
            return _post === post;
        }
        return false;
    }

}

export default Score;