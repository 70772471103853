import { useMediaQuery } from 'react-responsive';

export type DeviceType = 'tablet' | 'desktop' | 'mobile';

const getMediaCondition = (device: DeviceType): string => {
    switch (device){
        case 'mobile':
            return '(max-width: 688px)';
        case 'tablet':
            return '(min-device-width: 1824px)';
        default:
            return '(min-device-width: 1824px)';
    }
}

const useDeviceQuery = (device: DeviceType) => {
   return useMediaQuery({query: getMediaCondition(device)});
};

export default useDeviceQuery;