import {useEffect, useState} from 'react';
import MainService from "../../common/core/MainService";

const puzzleService = new MainService();

const useFetchPuzzle = (id?: string) => {
    const [payload, setPayload] = useState<any>();

    useEffect(() => {
        puzzleService.getAllPuzzlesWithPosts()
            .then( (data: any) => {
                if (!Array.isArray(data)){
                    setPayload([]);
                }
                setPayload(data);
            })
    }, [])
    return payload;
}

export default useFetchPuzzle;