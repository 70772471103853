import {useCallback, useEffect, useState} from "react";
import {Post, PostScore, Puzzle, PuzzleScore, ScoreCard, TaskBase, TaskScore} from "../../common/types";
import PersistentStore from "../../common/classes/PersistentStore";

const initScoreCardFromPuzzles = (puzzles?: Puzzle[]): ScoreCard => {
    const buildTasks = (tasks?: TaskBase[]): TaskScore[] => {
        if (!Array.isArray(tasks)) return [];
        let _tasks = tasks.map((task: TaskBase, i) => {
            return {
                idx: i,
                completed: task?.taskType === "context",
                isTask: task?.taskType !== "context"
            }
        })
        return _tasks ? _tasks : [];
    }
    const buildPosts = (posts?: Post[]): PostScore[] => {
        if (!Array.isArray(posts)) return [];
        let _posts = posts.map((post: Post, i): PostScore => {
            return {
                id: post.id ?? 0,
                tasks: buildTasks(post?.tasks)
            }
        })
        _posts = _posts.filter((p) => p.id !== 0);
        return _posts;
    }
    const buildPuzzles = (puzzles?: Puzzle[]): PuzzleScore[] => {
        if (!Array.isArray(puzzles)) return [];
        let _puzzles = puzzles.map((puzzle: Puzzle) => {
            return {
                id: puzzle.id ?? 0,
                score: 0,
                posts: buildPosts(puzzle?.posts)
            }
        })
        _puzzles = _puzzles.filter((p) => p.id !== 0);
        return _puzzles;
    }
    if (!puzzles) return {puzzles: []};
    return {puzzles: buildPuzzles(puzzles)};
}

const sha256 = async (object: any) => {
    const data = new TextEncoder().encode(object);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
}

const loadScore = async (puzzles?: Puzzle[]) => {
    const hashObject = async (obj: any) => {
        if (typeof obj !== 'object') return false;
       return sha256(obj);
    }
    const createNewScore = (hash: boolean | string) => {
        console.log("Creating new score card...");
        let initiatedScore = initScoreCardFromPuzzles(puzzles);
        PersistentStore.save('hash', hash);
        PersistentStore.save('score', initiatedScore);
        return initiatedScore;
    }
    const processWithHash = (puzzleHash: boolean | string) => {
        let storedScore = PersistentStore.get('score');
        let lastHash = PersistentStore.get('hash');
        if (lastHash && storedScore && (puzzleHash === lastHash)) {
            console.log("Using cached score card...");
            return storedScore;
        }
        return createNewScore(puzzleHash);
    }
    return hashObject(puzzles).then(processWithHash).catch( () => {
        return createNewScore("123");
    });
}

const useScoreCard = (puzzles?: Puzzle[]) => {
    const [scoreCard, setScoreCard] = useState<ScoreCard>({
        puzzles: []
    });

    const registerTask = (puzzleId: number, postId: number, taskIdx: number, score: number) => {
        setScoreCard((prev) => {
            let tmp = {...prev};
            try {
                tmp.puzzles.forEach((puzzle: any) => {
                    if (puzzle?.id === puzzleId) {
                        puzzle.posts.forEach((post: any) => {
                            if (post?.id === postId) {
                                if (!post.tasks[taskIdx].completed) {
                                    puzzle.score += score;
                                    post.tasks[taskIdx].completed = true;
                                }
                            }
                        })
                    }
                })
            } catch (err) {
                console.warn("Error while registering task. Err: ", err);
            }
            PersistentStore.save('score', tmp);
            return tmp;
        })
    }

    const updateCard = useCallback((card: ScoreCard) => {
        PersistentStore.save('score', card);
        setScoreCard(card);
    }, [])

    useEffect(() => {
        if (!puzzles || puzzles.length === 0) return;
        loadScore(puzzles).then( (scoreCard: ScoreCard) => {
            setScoreCard(scoreCard);
        });
    }, [puzzles])

    return {
        scoreCard: scoreCard,
        registerTask: registerTask,
        setScoreCard: setScoreCard,
        updateCard
    }
};

export default useScoreCard;