import React, {useMemo, useState} from 'react';
import styled from "styled-components";

export interface SizesSize {
    large: string;
    medium: string;
    small: string;
}

export interface ImageProps {
    src?: string;
    alt?: string;
    height?: string;
    width?: string;
    sizes?: SizesSize;
}
const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
`;

const Img = styled.img<Omit<ImageProps, 'sizes'>>`
  display: block;
  height: ${({height}) => height ?? '100%'};
  width: ${({width}) => width ?? '100%'};
  object-fit: cover;
`;

const cleanSrc = (src: string) => {
    return src.includes('?') ? src.split('?')[0] : src;
};

const ImageDimensions = [167, 250, 400, 600, 800, 1200];

const generateLowSrc = (src: string) => {
    if (!src) return '';
    if (!(src.includes('dms') && src.includes('/image'))) return src;
    let dims = ImageDimensions;
    let _src = cleanSrc(src);
    return `${_src}?dimension=${dims[0]}x${dims[0]}`;
}

const Image: React.FC<ImageProps> = (props) => {
    const { children, height, width, src, alt, sizes } = props;
    const [lowImgLoaded, setLowImgLoaded] = useState<boolean>(false);
    const _sizes = useMemo( () => {
         return sizes
             ? `(min-width: 1024px) ${sizes.large}, (min-width: 768px) ${sizes.medium}, ${sizes.small}`
             : '100vw';
    }, [sizes])
    const srcSet = useMemo( () => {
        if (!src) return '';
        if (!(src.includes('dms') && src.includes('/image'))) return src;
        let _str = '';
        const _src = cleanSrc(src);
        ImageDimensions.forEach( (dim: number, i: number) => {
            _str += `${_src}?dimension=${dim}x${dim} ${dim}w${i < ImageDimensions.length - 1 ? ',' : ''}`;
        });
        return _str;
    }, [src]);


    const onLoadHandler = () => {
        setLowImgLoaded(true);
    }

    return (
        <Container>
            <Img
                onLoad={onLoadHandler}
                width={width}
                height={height}
                src={src && generateLowSrc(src)}
                srcSet={lowImgLoaded ? srcSet : ''}
                sizes={_sizes}
                alt={alt}/>
            <Overlay>
                {children}
            </Overlay>
        </Container>
    );
};

export default Image;