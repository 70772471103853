import {useHistory} from "react-router-dom";
import {useEffect} from "react";


const getLocationId = ({pathname, search, hash}: any) => {
    return pathname + (search ? "?" + search : "") + (hash ? "#" + hash : "");
}
const useLocationBlocker = () => {
   const history = useHistory();
   useEffect( () =>
       history.block(
           (location: any, action: any) =>
               action && (action !== "PUSH" ||
               getLocationId(location) !== getLocationId(history.location))
       ), [history])
    return history;
};

export default useLocationBlocker;